.App {
  text-align: center;
}

.App-logo {
  height: 12vmin;
}

.App-header {
  background-color: #262626;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media only screen and (max-width: 799px) {
  .App-header h2 {
    margin: 0px 8% 0px 8%;
  }
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: Haas Grot Text R,sans-serif !important;
}

.lead-in {
  font-size: 0.85em;
}

.arrow {
  height: 30px;
}

@media only screen and (min-width: 800px) {
  .faq {
    margin: 0px 22% 0px 22%;
  }
  
}

@media only screen and (max-width: 799px) {
  .faq {
    margin: 0px 8% 0px 8%;
  }
}

.faq p {
  text-align: left;
  margin-bottom: 3%;
}

.faq h4 {
  margin-top: 5%;
}

.App-footer {
  background-color: #262626;
  min-height: 10vh;
  margin-top: 5%;
}

.App-logo-footer {
  max-height: 4vh;
  margin: 1.5% 0% 1.5% 0%;
}